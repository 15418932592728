<template>
  <div
    class="absolute bottom-0 left-0 md:relative rounded-t-16 sm:h-auto max-h-fill w-screen md:w-447 flex flex-col sm:rounded-20 p-4 bg-woodsmoke justify-start overflow-auto"
  >
    <button
      id="close-button"
      class="cursor-pointer w-6 h-6 flex items-center justify-center absolute top-4 right-4"
      @click="close"
    >
      <i alt="close" class="w-3 h-3 icon-close" />
    </button>
    <LoginForm
      @changeAuthType="changeAuthType"
      @userAuthenticated="userAuthenticated"
    />
  </div>
</template>

<script setup lang="ts">
import { AuthActionTypes } from "@/types/authentication";
import LoginForm from "./LoginForm.vue";

const emit = defineEmits(["close", "changeAuthType", "userAuthenticated"]);

const changeAuthType = (authType: AuthActionTypes) => {
  emit("changeAuthType", authType);
};

const userAuthenticated = () => {
  emit("userAuthenticated");
};

const close = () => {
  emit("close");
};
</script>

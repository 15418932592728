<template>
  <sample-modal
    overlay-class="modal-opacity-bg overflow-auto"
    container-class="rounded-20"
    additionalClasses="z-50"
    @close-modal="close"
  >
    <template #main>
      <login-template
        v-if="actionType === 'login'"
        @close="close"
        @change-auth-type="changeAuthType"
        @user-authenticated="userAuthenticated"
      />
      <sign-up-component
        v-if="actionType === 'signup'"
        :custom-title="
          isFeedbackModal
            ? 'Please Create an Account First'
            : isPlaylistSubmissionModal
            ? 'Create Your Artist Profile'
            : ''
        "
        @close="close"
        @change-auth-type="changeAuthType"
        @userAuthenticated="userAuthenticated"
      />
      <forgot-password-component
        v-if="actionType === 'forgotPassword'"
        @change-auth-type="changeAuthType"
        @on-pwd-sent="manageNotification"
      />
      <reset-password-component
        v-if="actionType === 'newPassword'"
        @change-auth-type="changeAuthType"
      />
    </template>
  </sample-modal>
</template>

<script setup lang="ts">
import { useLocalNotificationsStore } from "@/store/notifications";
import { AuthActionTypes } from "@/types/authentication";
import { PropType, ref, watch } from "vue";
import SampleModal from "../../components/modal/SampleModal.vue";
import ForgotPasswordComponent from "./ForgotPassword.vue";
import LoginTemplate from "./Login.vue";
import ResetPasswordComponent from "./ResetPassword.vue";
import SignUpComponent from "./SignUp.vue";

const props = defineProps({
  defaultActionType: {
    type: String as PropType<AuthActionTypes> | undefined,
  },
  isFeedbackModal: {
    type: Boolean,
    default: false,
  },
  isPlaylistSubmissionModal: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["close", "userAuthenticated"]);

const localNotificationsStore = useLocalNotificationsStore();
const actionType = ref(props.defaultActionType);

watch(
  () => props.defaultActionType,
  () => {
    actionType.value = props.defaultActionType || AuthActionTypes.signup;
  },
);

const changeAuthType = (type: AuthActionTypes) => {
  actionType.value = type;
};

const manageNotification = () => {
  localNotificationsStore.addNotification({
    message: "Link has been sent. Please check your email for instructions.",
    isError: false,
  });
};

const userAuthenticated = () => {
  emit("userAuthenticated");
};

const close = () => {
  emit("close");
};
</script>

<style>
.modal-opacity-bg::after {
  content: " ";
  background: url("https://ik.imagekit.io/xposuremusic/bg-noise.png");
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.8;
}
</style>

<template>
  <AuthModal
    v-if="modalState?.isModalVisible"
    :is-feedback-modal="modalState.isFeedbackModal"
    :is-playlist-submission-modal="modalState.isPlaylistSubmissionModal"
    :default-action-type="modalState.defaultActionType"
    @close="authModalClose"
    @user-authenticated="userAuthenticated"
  />
</template>

<script setup lang="ts">
import { useAuthenticationStore } from "@/store/authentication";
import { useLocalNotificationsStore } from "@/store/notifications";
import { useUserStore } from "@/store/user";
import { AuthActionTypes } from "@/types/authentication";
import { ref, watch, onMounted } from "vue";
import AuthModal from "../authentication/AuthModal.vue";
import { useGtm } from "@gtm-support/vue-gtm";
import { useProductsStore } from "@/store/products";
import AnalyticsService from "@/services/analytics-service";
import { useUsersStore } from "@/store/users";
import { setCookie } from "@/utils/helpers";

const router = useRouter();

const userStore = useUserStore();
const usersStore = useUsersStore();
const authStore = useAuthenticationStore();
const analyticsService = new AnalyticsService("analytics");
const localNotifications = useLocalNotificationsStore();
const productsStore = useProductsStore();

const modalState = ref(authStore.modalState);
watch(
  () => authStore.modalState,
  () => {
    if (authStore.modalState.isModalVisible) {
      setCookie("signup_page_source", router.currentRoute.value.path, 30);
    }
    modalState.value = authStore.modalState;
  },
);

const twitterAuthentication = async () => {
  try {
    const codeVerifier = localStorage.getItem(
      "twitter_code_verifier",
    ) as string;
    const proCode = localStorage.getItem("twitter_code_pro") as string;
    localStorage.removeItem("twitter_code_pro");
    localStorage.removeItem("twitter_code_verifier");

    if (codeVerifier) {
      const { state, code } = router.currentRoute.value.query;
      const { access_token } = await authStore.twitterCallback(
        state as string,
        code as string,
        codeVerifier,
      );
      await authStore.twitterLogin(access_token, proCode);
      userAuthenticated();
    }
  } catch (error: any) {
    localNotifications.addNotification({
      message: error.message,
      isError: true,
    });
  }
};

const googleAuthentication = async () => {
  try {
    const googleLoginCode = router.currentRoute.value?.query?.code;
    const proCode = localStorage.getItem("google_code_pro") as string;

    try {
      const { auth_token } = await authStore.googleCallback(
        googleLoginCode as string,
        proCode,
      );
      authStore.setToken(auth_token);
      localStorage.setItem("authToken", auth_token);
    } catch (e) {
      router.replace(router.currentRoute.value.path);
      return;
    }

    const { user } = await userStore.getMe();
    analyticsService.identify(user);

    localStorage.removeItem("google_code_pro");

    const gtm = useGtm();
    gtm?.trackEvent({
      event: "login",
      authenticationMethod: "google",
      userId: userStore.userData._id,
    });

    const GAuthProductId = localStorage.getItem("GAuthProductId") as string;
    const GAuthProId = localStorage.getItem("GAuthProId") as string;
    if (GAuthProId) {
      const pro = await usersStore.getById(GAuthProId);
      if (pro) {
        productsStore.setSelectedPro(pro);
      }
    }
    if (GAuthProductId) {
      if (GAuthProductId === "music-library") {
        router.push("/submit/track");
        return;
      } else {
        const products = await productsStore.getProductsByProId(GAuthProId);
        if (products) {
          const GAuthProduct = products.find(
            (product) => product._id === GAuthProductId,
          );
          if (GAuthProduct) {
            productsStore.setPurchaseProduct(GAuthProduct);
            analyticsService.trackCheckoutInitiated(GAuthProductId);
            router.push("/purchase/checkout/select-track");
          } else {
            localNotifications.addNotification({
              message: "Product not found",
              isError: true,
            });
          }
        } else {
          localNotifications.addNotification({
            message: "Pro not found",
            isError: true,
          });
        }
        return;
      }
    }

    const chatStore = (await import("@/store/chat")).useChatStore();
    if (!process.server) chatStore.initChatService();

    await router.push("/dashboard");
  } catch (error: any) {
    localNotifications.addNotification({
      message: error.message,
      isError: true,
    });
  }
};

const initAuthModalState = () => {
  modalState.value = authStore.modalState;
  const resetToken = router.currentRoute.value.query?.resetPassword;
  if (resetToken) {
    authStore.setModalState({
      isModalVisible: true,
      defaultActionType: AuthActionTypes.newPassword,
    });
  } else if (router.currentRoute.value?.query?.twitterAuth === "true") {
    twitterAuthentication();
  } else if (router.currentRoute.value?.query?.googleCallback === "true") {
    googleAuthentication();
  }
};

const userAuthenticated = () => {
  if (modalState.value.redirectUrl === "/purchase/checkout/select-track") {
    const productId = productsStore.purchaseProduct?._id;
    if (productId) {
      analyticsService.trackCheckoutInitiated(productId);
    }
  }
  router.push(modalState.value.redirectUrl || "/dashboard");
  authModalClose();
};

const authModalClose = () => {
  authStore.setModalState({
    isModalVisible: false,
  });
  localStorage.removeItem("GAuthProductId");
  localStorage.removeItem("GAuthProId");
};

onMounted(() => {
  initAuthModalState();
});
</script>

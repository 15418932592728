<template>
  <div
    class="absolute bottom-0 left-0 md:relative w-full sm:w-447 sm:mx-0 flex flex-col rounded-20 px-6 py-8 sm:px-14 sm:py-20 bg-woodsmoke items-center text-center"
  >
    <span v-if="errorMessage" class="text-red">{{ errorMessage }}</span>

    <h1 class="text-xl text-grayscale-96 leading-160 font-druk-wide">
      Create New Password
    </h1>

    <p class="mt-6 text-manatee text-sm">
      Create a new password for your account. We recommend creating a strong
      password with a combination of letters, digits and symbols.
    </p>

    <form class="w-full mt-10">
      <input-field
        v-model:value="password"
        name="password"
        placeholder="New password"
        type="password"
        class="mt-2"
        :is-disabled="isLoading"
        v-model:error-message="errors.passwordError"
        @clearError="clearError"
        @proceed="checkInput"
      />
    </form>

    <button
      id="sign-up-button"
      class="mt-8 bg-accent-orange w-full rounded-54 h-46 text-base text-grayscale-7 font-bold"
      :disabled="isLoading"
      @click="checkInput"
    >
      Change password
    </button>
  </div>
</template>

<script setup lang="ts">
import { useAuthenticationStore } from "@/store/authentication";
import { AuthActionTypes, NewPasswordError } from "@/types/authentication";
import ApiError from "@/types/error";
import { runWithDelay, scrollToFirstError } from "@/utils/helpers";
import { validatePassword } from "@/utils/validation";
import { defineComponent, onMounted, reactive, ref, toRefs } from "vue";
import InputField from "../layout/inputs/InputField.vue";

const router = useRouter();

const emit = defineEmits(["changeAuthType"]);

const errors = reactive({ passwordError: "" } as NewPasswordError);
const password = ref("");
const isLoading = ref(false);
const errorMessage = ref("");
const resetToken = ref("");

const authenticationStore = useAuthenticationStore();

const getResetToken = () => {
  resetToken.value = router.currentRoute.value.query?.resetPassword as string;

  if (resetToken.value) {
    const { resetPassword, ...newQuery } =
      router.currentRoute.value.query || {};

    router.replace({
      path: router.currentRoute.value.path,
      query: newQuery,
    });
  }
};

const checkInput = async (): Promise<void> => {
  isLoading.value = true;
  errors.passwordError = validatePassword(password.value);
  if (errors.passwordError) {
    scrollToFirstError(errors);
    runWithDelay(() => {
      errors.passwordError = "";
    });
    isLoading.value = false;
    return;
  }

  try {
    await authenticationStore.resetPassword(password.value, resetToken.value);
    emit("changeAuthType", AuthActionTypes.login);
  } catch (error) {
    errorMessage.value = (error as ApiError).message;
    runWithDelay(() => (errorMessage.value = ""));
  }

  isLoading.value = false;
};

const clearError = (inputName: keyof NewPasswordError): void => {
  if (Object.prototype.hasOwnProperty.call(errors, inputName)) {
    errors[inputName] = "";
  }
};

onMounted(() => {
  getResetToken();
});
</script>

<template>
  <div
    class="absolute bottom-0 left-0 md:relative w-full sm:w-447 flex flex-col rounded-t-16 px-6 py-8 sm:px-14 sm:py-20 bg-woodsmoke items-center text-center"
  >
    <button
      id="close-button"
      class="cursor-pointer w-6 h-6 flex items-center justify-center absolute top-4 right-4"
      @click="goLogin"
    >
      <img src="@/assets/icons/close.png" alt="close" class="w-3 h-3" />
    </button>

    <span v-if="errorMessage" class="text-red">{{ errorMessage }}</span>

    <h1 class="text-xl text-grayscale-96 leading-160 font-druk-wide">
      Forgot Your Password?
    </h1>

    <p class="mt-6 text-manatee text-sm">
      Please leave your email and we’ll send you a link to reset password. Don’t
      want to change it?
    </p>

    <button class="mt-2 text-sm font-medium text-blue-haze" @click="goLogin">
      Back to Login
    </button>

    <form class="w-full mt-10">
      <input-field
        v-model:value="email"
        name="email"
        placeholder="Email"
        type="text"
        :is-disabled="isLoading"
        v-model:error-message="errors.emailError"
        @clearError="clearError"
        @proceed="checkInput"
      />
    </form>

    <button
      id="sign-up-button"
      class="mt-8 bg-accent-orange w-full rounded-54 h-46 text-base text-grayscale-7 font-bold"
      :disabled="isLoading"
      @click="checkInput"
    >
      Request a password reset
    </button>
  </div>
  <div
    class="absolute left-0 right-0 bottom-20 md:bottom-2 mx-auto max-w-96 xl:max-w-1142 w-full z-50 transition-all ease-in-out duration-200"
  >
    <pop-up-notification
      v-for="(notification, index) in localNotificationsStore.notifications"
      :key="notification.id"
      :index="index"
      :is-error="notification.isError"
      :is-visible="notification.isVisible"
      :message="notification.message"
      @close="localNotificationsStore.hideNotification(notification.id)"
    />
  </div>
</template>

<script setup lang="ts">
import { useAuthenticationStore } from "@/store/authentication";
import { AuthActionTypes, ForgotPasswordError } from "@/types/authentication";
import ApiError from "@/types/error";
import { runWithDelay, scrollToFirstError } from "@/utils/helpers";
import { validateEmail } from "@/utils/validation";
import { defineComponent, reactive, ref, toRefs } from "vue";
import InputField from "../layout/inputs/InputField.vue";
import { useLocalNotificationsStore } from "@/store/notifications";
import PopUpNotification from "../layout/notifications/PopUpNotification.vue";

const emit = defineEmits(["changeAuthType", "onPwdSent"]);

const localNotificationsStore = useLocalNotificationsStore();

const errors = reactive({
  emailError: "",
} as ForgotPasswordError);
const email = ref("");
const isLoading = ref(false);
const showNotification = ref(false);
const errorMessage = ref("");

const authenticationStore = useAuthenticationStore();

const checkInput = async (): Promise<void> => {
  isLoading.value = true;
  errors.emailError = validateEmail(email.value);
  if (errors.emailError) {
    scrollToFirstError(errors);
    isLoading.value = false;
    return;
  }

  try {
    await authenticationStore.forgotPassword(email.value);
    emit("onPwdSent");
  } catch (error) {
    errorMessage.value = (error as ApiError).message;
    runWithDelay(() => (errorMessage.value = ""));
  }

  isLoading.value = false;
};

const clearError = (inputName: keyof ForgotPasswordError): void => {
  if (Object.prototype.hasOwnProperty.call(errors, inputName)) {
    errors[inputName] = "";
  }
};

const goLogin = () => {
  emit("changeAuthType", AuthActionTypes.login);
};
</script>
